import React from "react";
import SEO from "../../components/seo";
import Blog from "../../components/Blog";
import Par from "../../components/Par";
import H1 from "../../components/h1";

const title = "Helicobacter Pylori";

export default () => (
	<Blog title={title}>
		<SEO
			title={title}
			keywords={[title, "H Pylori", "Peptic Ulcer Disease", "Gastric cancer", "Asymptomatic H Pylori", "Dyspepsia"]}
			type="article"
			description="Helicobacter Pylori - the bacteria present in 30% of Australians and often causes peptic ulcer disease and gastic cancer."
		/>
		<H1>{title}</H1>
		<Par>
			Helicobactor pylori is present in approximately 30% of adult Australians but is not uniformly distributed in the
			population. The prevalence of infection is higher in older people, migrants, those with lower socio-economic
			status and the institutionalised.
		</Par>
		<Par>
			Most people with infections of H pylori are asymptomatic but infection conveys a lifetime risk of peptic ulcer
			disease of 15-20% and of gastric cancer of up to 2%. While everyone develops an active chronic gastritis, there is
			an inconsistent relationship between the presence of H pylori gastritis and symptoms. H pylori is directly
			implicated in causing both gastric and duodenal ulcers, eradication in these patients leads to long term remission
			of their symptoms. In contrast only a minority of people with H pylori gastritis without peptic ulcer disease and
			who have symptoms of non ulcer dyspepsia will get sustained relief of their symptoms after eradication therapy.
		</Par>
		<Par>Indications to treat are:</Par>
		<ul>
			<li>Peptic ulcer disease, past or present</li>
			<li>NSAID and aspirin users</li>
			<li>First degree relatives of patients with gastric cancer</li>
			<li>Low grade gastric MALT lymphoma</li>
			<li>Dyspepsia</li>
			<li>Atrophic gastritis and intestinal metaplasia</li>
			<li>Patients requiring long term acid suppression</li>
			<li>Patients with treated early gastric cancer</li>
		</ul>
		<h2 className="mt-3">Eradication of H pylori</h2>
		<Par>Therapy:</Par>
		<ol>
			<li>
				Esomeprazole 20 mgs BD x 7 days
				<div className="my-2">
					<b>Plus</b>
				</div>
			</li>
			<li>
				Amoxicillin 1 gm BD x 7 days
				<div className="my-2">
					<b>Plus</b>
				</div>
			</li>
			<li>Clarithromycin 500g BD. x 7 days</li>
		</ol>
		<Par>
			This regime did achieve success rates of 85-90% in clinical trials initially but there is an increasing incidence
			of clarithromycin resistance and now the success rate of eradication with this regime may be closer to 60-70%. If
			this regime fails then a repeat course has a less than 10% chance of being effective and should not be used. Side
			effects including taste disturbance, nausea and diarrhoea are common. Eradication rates are strongly related to
			compliance, i.e. if the patient misses tablets then eradication rates are reduced significantly.
		</Par>
		<h2 className="mt-3">Eradication therapy in penicillin hypersensitive</h2>
		<ol>
			<li>
				Standard dose PPI BD x 7 days
				<div className="my-2">
					<b>Plus</b>
				</div>
			</li>
			<li>
				Metronidazole 400mg BD x 7 days
				<div className="my-2">
					<b>Plus</b>
				</div>
			</li>
			<li>Clarithromycin 500 mgs BD x 7 days</li>
		</ol>
		<h2 className="mt-3">Failure of eradication therapy</h2>
		<Par>
			Clarithromycin resistance is very common and the incidence increases after failure of first line therapy. Repeat
			treatment with a clarithromycin containing combination has a very low rate of success and should be avoided.
		</Par>
		<Par>
			There are a range of regimens available for second line or 'salvage' therapy. These are constantly changing and
			referral to a specialist with an interest in this area is preferable to repeated efforts with combinations that
			are unlikely to be effective. Sequential regimes and quadruple therapy regimes can be used in this situation.
		</Par>
		<Par>
			Referral for endoscopy with biopsies taken for culture and testing for antibiotic sensitivity may be appropriate.
			It is important that prior to any testing no antibiotics should be taken for at least one month and PPIs should be
			suspended for one week at least and two weeks if possible to minimise the chance of false negative results. There
			is considerable variation in laboratories experience and ideally a laboratory with a track record of successfully
			culturing H pylori should be employed.
		</Par>
	</Blog>
);
